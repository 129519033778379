html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}
::-ms-reveal {
  display: none;
}
.is-invalid {
  background: none !important;
  padding-right: 0 !important;
}
h4,
h5 {
  margin-bottom: 0px;
}

@font-face {
  font-family: "boldFont";
  src: local("boldFont"),
    url(./assets/fonts/AvenirNextCyr-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "regularFont";
  src: local("regularFont"),
    url(./assets/fonts/AvenirNextCyr-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "mediumFont";
  src: local("mediumFont"),
    url(./assets/fonts/AvenirNextCyr-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "demiFont";
  src: local("demiFont"),
    url(./assets/fonts/AvenirNextCyr-Demi.ttf) format("truetype");
}
