.costview-headercard {
  background-color: white;
  border: 1px solid black;
  border-radius: 5px !important;
  padding: 15px;
}

.costview-cardContent {
  background-color: white;
  border: 1px solid #8f8f8f;
  padding: 10px;
  box-shadow: -1px 8px 7px -3px rgba(72, 61, 61, 0.73);
  -webkit-box-shadow: -1px 8px 7px -3px rgba(72, 61, 61, 0.73);
  -moz-box-shadow: -1px 8px 7px -3px rgba(72, 61, 61, 0.73);
}

.costview-table {
  border-bottom: 1px solid #8f8f8f;
}
.costview-table td {
  padding: 20px;
  font-family: mediumFont;
  color: black;
}
.costview-header td {
  font-family: demiFont;
  color: #000;
}

@media screen and (max-width: 768px) {
  .slider-inner {
    text-align: center;
    max-height: 400px;
    height: 375px;
  }
}

@media screen and (max-width: 480px) {
  .slider-inner {
    text-align: center;
    max-height: 400px;
    height: 375px;
  }
}

@media screen and (max-width: 380px) {
  .slider-inner {
    text-align: center;
    max-height: 400px;
    height: 400;
  }
}

@media screen and (max-width: 839) {
  .slider-inner {
    text-align: center;
    max-height: 400px;
    height: 400;
  }
}
