.slab-table1 td {
  padding: 10px !important;
}

.createSlabBtn {
  background-color: #35b968 !important;
  color: white !important;
  border-radius: 20px !important;
  padding: 15px 80px !important;
}
