.error-position-slab {
  position: absolute;

  right: 5px;
  bottom: -8px;
  padding: 0px 5px !important;
  background-color: #f3f3f3 !important;
}

.error-position-slab span {
  color: red;
  font-family: mediumFont;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.input-slab {
  margin-left: 15px;
  max-width: 50%;
  color: rgb(245, 0, 87);
  outline: none;
  border: none;
  background-color: rgb(204, 252, 236);
}
.input-slab-sp {
  margin-left: 15px;
  max-width: 100%;
  color: rgb(245, 0, 87);
  outline: none;
  border: none;
}
.input-pink {
  color: rgb(245, 0, 87);
}
.input-slab-bg {
  background-color: #00000000;
}
.input-content-slab {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.slab-ht-table tbody tr td {
  text-align: center;
  vertical-align: middle;
  padding: 12px;
}

element.style {
  border: 1px solid red;
  position: relative;
}
.slab-ht-table tbody tr td {
  text-align: center;
  vertical-align: middle;
  padding: 12px;
}
.slab-ht-table > :not(caption) > * > * {
  border-bottom-width: 1px !important;
}
.slab-ht-table tbody tr td input {
  padding: 20px 5px;
  background: transparent;

  width: 120px;
}
.slab-lt-table tbody tr td input {
  padding: 20px 5px;
  background: transparent;
  width: 120px;
  color: "black" !important;
}

.slab-lt-table thead tr th {
  border: 1px solid black;
  vertical-align: middle;
}
.slab-lt-table tbody tr td {
  padding: 12px;
  vertical-align: middle;
  text-align: center;
}

.slab-lt-table > :not(caption) > * > * {
  border-bottom-width: 1px !important;
}
.lt-input {
  border: none;
  outline: none;
  text-align: center;
  color: black;
}

.ht-accordion {
  width: 100%;
  padding: 20px 10px !important;
  background-color: beige !important;
  margin-bottom: 15px;
  margin-top: 15px;
  border: 1px solid rgb(114, 114, 100);
  border-radius: 5px;
}

.ht-accordion:hover {
  background-color: rgb(250, 213, 154) !important;
}

.htaccordion_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
