.login__container {
  display: flex;
  height: 80vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.sub-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
}
.login__container p {
  font-family: demiFont;
}

.signup {
  text-decoration: none;
  color: #4059e2;
}

.login__header {
  font-family: demiFont;
  color: #000000;
  margin-top: 10px;
}

.login__main {
  margin-top: 25px;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.inputBox {
  display: flex;
  flex-direction: column;

  margin-top: 10px;
  position: relative;

  width: 55%;
}

.inputBox label {
  margin-top: 5px;
}

.inputBox input {
  margin-top: 5px;
  padding: 15px;
  border: 1px solid #b3cdff80;
  border-radius: 3px;
  width: 240px;
  font-size: 16px;
  font-family: mediumFont;
}

.inputBox input:active,
.inputBox input:focus {
  outline: none;
}

.password__icon {
  position: absolute;
  right: 10px;
  top: 55%;
  cursor: pointer;
}

.err {
  position: absolute;
  bottom: -8px;
  right: 10px;
  background: linear-gradient(#ffffff, #f8f8f8);
  color: #cc0000;
  padding: 0 5px;
  margin: 0;
  font-size: 14px;
}

.forget__password {
  position: absolute;
  top: 110px;
  right: 0;
  margin-top: 10px;
  text-decoration: none;
  color: #0c60ff;
}

.forget__link {
  text-decoration: none;
  color: #0c60ff;
}

.inputCheckBox {
  display: flex;
  align-items: center;
  margin-top: 40px;
}

#signin {
  padding: 10px 25px;
  margin-top: 10px;
  background-color: #4059e2;
  color: #fff;
  font-family: boldFont;
}
@media only screen and (max-width: 992px) {
  .inputBox {
    width: 70%;
  }

  .sub-container {
    width: 70%;
  }
}

@media only screen and (max-width: 776px) {
  .inputBox {
    width: 80%;
  }

  .sub-container {
    width: 80%;
  }
}
@media only screen and (max-width: 556px) {
  .inputBox {
    width: 95%;
  }

  .sub-container {
    width: 100%;
  }
}
