.tool_container {
  background-color: white;
  border-radius: 5px;
  padding-bottom: 20px;
  margin-bottom: 30px;
  border: 1px solid rgb(184, 184, 184);
  box-shadow: -1px 8px 7px -3px rgba(72, 61, 61, 0.73);
  -webkit-box-shadow: -1px 8px 7px -3px rgba(72, 61, 61, 0.73);
  -moz-box-shadow: -1px 8px 7px -3px rgba(72, 61, 61, 0.73);
}

.formcalc {
  width: 49% !important;
  padding: 20px;
}
.transac_graph {
  width: 49% !important;
  margin-left: 2%;
  padding: 20px;
}
.transaction_input {
  padding: 16px 16px;
}

.table_header {
  background-color: #000000c7 !important;
  text-align: center;
  color: white;
  font-family: mediumFont;
}

.table_total {
  background-color: #6a707e91 !important;
  text-align: center;
  color: black;
  font-family: mediumFont;
  border-bottom: 1px solid #6a707e91;
  border-left: 1px solid #6a707e91;
  border-right: 1px solid #6a707e91;
}
.table_total td {
  font-family: demiFont;
}

.table_sub {
  background-color: #f9fafb !important;
  text-align: center;
  color: black;
  font-family: mediumFont;
  vertical-align: middle;
  border: 1px solid #96989b;
}
#price_table {
  border-color: #dee2e6;
}
